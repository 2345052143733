<template>
	<div class="inputBox" style="display: inline-block;">
		<!-- titles对每个字段生成了三个类型数组，循环显示，造成三个一组的方式 -->
		<div v-if="joined" style="display: inline-block; " v-for="(t, i) in titles" :key="i">
			<div v-if="t.type == 'str'">
				{{ t.value }}
			</div>
			<div v-if="t.type == 'dom'">
				<el-input disabled v-model="inputValue" size="small" style="width:300px;"></el-input>
			</div>
		</div>
		<div v-if="!joined" style="display: inline-block; " v-for="(t, i) in titles" :key="i">
			<div v-if="t.type == 'str'">
				{{ t.value }}
			</div>
			<div v-if="t.type == 'dom'">

				<div v-if="t.value == 'city'">
					<el-cascader :ref="'city' + domid" v-model="inputCode" size="small" style="width:300px;"
						@change="selectCity" :props="treeProps" clearable :options="areas" placeholder="请选择地区">
					</el-cascader>
				</div>
				<div v-if="t.value == 'select'">
					<el-select v-model="inputValue" size="small" style="width:300px;" @change="getSelectItems"
						placeholder="请选择">
						<el-option v-for="(item, dix) in selectItems" :key="dix" :label="item" :value="item">

						</el-option>
					</el-select>
				</div>
				<div v-if="t.value == 'date'">
					<el-date-picker size="small" v-model="inputValue" align="right" type="date" placeholder="选择日期"
						value-format="yyyy-MM-dd" @change="getSelectItems" style="width:300px;">
					</el-date-picker>
				</div>
				<div v-if="t.value == 'input'">
					<el-input v-model="inputValue" size="small" @input="getSelectItems" placeholder="请输入"
						style="width:300px;"></el-input>
				</div>
				<div v-if="t.value == 'stu_name'" id="stu_name" :tmidx="tmidx">
					<el-input v-model="inputValue" size="small" @input="getSelectItems" placeholder="请输入"
						style="width:300px;"></el-input>
				</div>
				<div v-if="t.value == 'stu_phone'" id="stu_phone" :tmidx="tmidx">
					<el-input v-model="inputValue" size="small" @input="getSelectItems" type="number" placeholder="请输入"
						style="width:300px;"></el-input>
				</div>
				<div v-if="t.value == 'stu_class'" id="stu_class" :tmidx="tmidx">
					<el-input v-model="inputValue" size="small" @input="getSelectItems" placeholder="请输入"
						style="width:300px;"></el-input>
				</div>

				<div v-if="t.value == 'number'">
					<el-input v-model="inputValue" size="small" @input="getSelectItems" type="number" placeholder="请输入数字"
						style="width:200px;"></el-input>
				</div>
				<div v-if="t.value == 'entpirse'" style="position: relative;">
					<div style="position: absolute; width: 100%; height: 100%; top: 0; z-index: 1;" @click="showEnter">

					</div>
					<el-input v-model="inputValue" size="small" readonly placeholder="请选择" style="width:300px;"></el-input>
				</div>

				<div v-if="t.value == 'ent_type'" id="ent_type" :tmidx="tmidx">
					<el-input v-model="inputValue" size="small" @input="getSelectItems" placeholder="请输入"
						style="width:300px;"></el-input>
				</div>
				<div v-if="t.value == 'ent_hy'" id="ent_hy" :tmidx="tmidx">
					<el-input v-model="inputValue" size="small" @input="getSelectItems" placeholder="请输入"
						style="width:300px;"></el-input>
				</div>
				<div v-if="t.value == 'ent_area'" id="ent_area" :tmidx="tmidx">
					<el-input v-model="inputValue" size="small" @input="getSelectItems" placeholder="请输入"
						style="width:300px;"></el-input>
				</div>

			</div>

			
		</div>



		<el-drawer title="请输入企业全称" size="80%" :visible.sync="drawer" direction="rtl" @close="drawer = false">
			<div style="padding:0 20px">
				<el-input placeholder="请输入请输入企业全称" :autofocus="true" v-model="keyword" class="input-with-select">

					<el-button type="primary" slot="append" icon="el-icon-search" @click="searchEnter"></el-button>
				</el-input>
			</div>

			<div>
				<div v-for="(ent, i) in EnterList" :key="i" class="enteritem" @click="setEnter(ent)">
					<div> {{ ent.name }}</div>
					<div> {{ ent.creditCode }}</div>
				</div>

			</div>

		</el-drawer>

	</div>
</template>

<script>
import area from "../../utils/arae_value.js"

export default {
	props: ["tm", 'tmidx', 'joined', 'nvalue'],
	watch: {
		joined(n, o) {
			this.joined = n
		},
		nvalue(n, o) {
			this.inputValue = n
		},
	},
	data() {
		return {
			titles: [],
			treeProps: {
				children: 'children',
				label: 'label',
				value: 'value',
				emitPath: false,
			},
			areas: [],
			selectItems: [],
			inputValue: "",
			inputCode: "",
			domid: parseInt(Math.random() * 100000),
			drawer: false,
			keyword: "",
			EnterList: []

		}
	},
	mounted() {
		console.log(this.tm)
		let tm_title = this.tm.tm_title.split("（")[0]

		let titles = []
		if (this.joined) this.inputValue = this.tm.tiankongAnswer
		if (tm_title.indexOf("_${省市区}_") >= 0) {
			titles.push({ type: "str", value: tm_title.split("_${省市区}_")[0] });
			titles.push({ type: "dom", value: "city" })
			titles.push({ type: "str", value: tm_title.split("_${省市区}_")[1] })
		} else if (tm_title.indexOf("_${省市}_") >= 0) {
			titles.push({ type: "str", value: tm_title.split("_${省市}_")[0] });
			titles.push({ type: "dom", value: "input" })
			titles.push({ type: "str", value: tm_title.split("_${省市}_")[1] })
		} else if (tm_title.indexOf("_${现在时间}_") >= 0) {
			titles.push({ type: "str", value: tm_title.split("_${现在时间}_")[0] });
			titles.push({ type: "dom", value: "date" })
			titles.push({ type: "str", value: tm_title.split("_${现在时间}_")[1] })
			this.inputValue = this.getDateStr()
		} else if (tm_title.indexOf("_${选择时间}_") >= 0) {
			titles.push({ type: "str", value: tm_title.split("_${选择时间}_")[0] });
			titles.push({ type: "dom", value: "date" })
			titles.push({ type: "str", value: tm_title.split("_${选择时间}_")[1] })
		} else if (tm_title.indexOf("_${姓名}_") >= 0) {
			titles.push({ type: "str", value: tm_title.split("_${姓名}_")[0] });
			titles.push({ type: "dom", value: "stu_name" })
			titles.push({ type: "str", value: tm_title.split("_${姓名}_")[1] })
			if (!window.loadingStu) {
				window.loadingStu = true;
				setTimeout(() => {
					this.getStuInfo();

				}, 200);
			}

		} else if (tm_title.indexOf("_${普通输入框}_") >= 0) {
			titles.push({ type: "str", value: tm_title.split("_${普通输入框}_")[0] });
			titles.push({ type: "dom", value: "input" })
			titles.push({ type: "str", value: tm_title.split("_${普通输入框}_")[1] })
		} else if (tm_title.indexOf("_${数字}_") >= 0) {
			console.log("数字",tm_title)
			titles.push({ type: "str", value: tm_title.split("_${数字}_")[0] });
			titles.push({ type: "dom", value: "number" })
			titles.push({ type: "str", value: tm_title.split("_${数字}_")[1] })
		} else if (tm_title.indexOf("_${学生手机号}_") >= 0) {
			titles.push({ type: "str", value: tm_title.split("_${学生手机号}_")[0] });
			titles.push({ type: "dom", value: "number" })
			titles.push({ type: "str", value: tm_title.split("_${学生手机号}_")[1] })
			
		} else if (tm_title.indexOf("_${手机号}_") >= 0) {
			titles.push({ type: "str", value: tm_title.split("_${手机号}_")[0] });
			titles.push({ type: "dom", value: "stu_phone" })
			titles.push({ type: "str", value: tm_title.split("_${手机号}_")[1] })
			if (!window.loadingStu) {
				window.loadingStu = true;
				setTimeout(() => {
					this.getStuInfo();

				}, 200);
			}
		} else if (tm_title.indexOf("_${所在班级}_") >= 0) {
			titles.push({ type: "str", value: tm_title.split("_${所在班级}_")[0] });
			titles.push({ type: "dom", value: "stu_class" })
			titles.push({ type: "str", value: tm_title.split("_${所在班级}_")[1] })
			if (!window.loadingStu) {
				window.loadingStu = true;
				setTimeout(() => {
					this.getStuInfo();

				}, 200);
			}
		} else if (tm_title.indexOf("_${下拉框}_") >= 0) {
			titles.push({ type: "str", value: tm_title.split("_${下拉框}_")[0] });
			titles.push({ type: "dom", value: "select" })
			titles.push({ type: "str", value: tm_title.split("_${下拉框}_")[1] })
			this.selectItems = this.tm.selectItems.replace(/、/g, ',').replace(/\r/g, ',').replace(/，/g, ',').split(",")
		} else if (tm_title.indexOf("_${单位名称}_") >= 0) {
			titles.push({ type: "str", value: tm_title.split("_${单位名称}_")[0] });
			titles.push({ type: "dom", value: "entpirse" })
			titles.push({ type: "str", value: tm_title.split("_${单位名称}_")[1] })

		} else if (tm_title.indexOf("_${单位性质}_") >= 0) {
			titles.push({ type: "str", value: tm_title.split("_${单位性质}_")[0] });
			titles.push({ type: "dom", value: "ent_type" })
			titles.push({ type: "str", value: tm_title.split("_${单位性质}_")[1] })

		} else if (tm_title.indexOf("_${单位行业}_") >= 0) {
			titles.push({ type: "str", value: tm_title.split("_${单位行业}_")[0] });
			titles.push({ type: "dom", value: "ent_hy" })
			titles.push({ type: "str", value: tm_title.split("_${单位行业}_")[1] })

		} else if (tm_title.indexOf("_${单位所在地}_") >= 0) {
			titles.push({ type: "str", value: tm_title.split("_${单位所在地}_")[0] });
			titles.push({ type: "dom", value: "ent_area" })
			titles.push({ type: "str", value: tm_title.split("_${单位所在地}_")[1] })

		} else {
			titles.push({ type: "str", value: tm_title });
			titles.push({ type: "dom", value: "input" })
			titles.push({ type: "str", value: "" })
		}
		this.titles = titles
		this.areas = area
		// console.log(this.tm)
	},
	methods: {
		selectCity(e) {

			for (let a of area) {
				if (a.children) {
					for (let b of a.children) {
						if (b.children) {
							for (let c of b.children) {
								if (c.value == e) {
									this.inputValue = a.label + "," + b.label + "," + c.label
									this.$emit("setInput", this.tmidx, this.inputValue)
									break
								}
							}
						}
					}
				}
			}

		},
		getSelectItems() {
			this.$emit("setInput", this.tmidx, this.inputValue)
		},
		showEnter() {
			this.drawer = true
		},
		searchEnter() {
			if (this.keyword.trim()) {
				this.$http.post("/api/tyc_search_list", { enter_name: this.keyword.trim() }).then(res => {
					if (res.data) {
						this.EnterList = res.data
					}
				})
			}
		},
		setEnter(e) {
			this.inputValue = e.name
			this.checkTyc()
			this.drawer = false
		},
		checkTyc() {
			if (this.inputValue.trim()) {
				this.$http.post("/api/tyc_search", { enter_name: this.inputValue }).then(res => {
					if (res.data) {
						this.inputValue = res.data.name
						this.$emit("setInput", this.tmidx, this.inputValue)


						//一般一个问卷只有一个企业，所以直接用字段名字即可
						
						if ($("#ent_type").attr("tmidx")) {
							this.$emit("setInput", $("#ent_type").attr("tmidx"), res.data.companyOrgType || "")
						}
						if ($("#ent_hy").attr("tmidx")) {
							this.$emit("setInput", $("#ent_hy").attr("tmidx"), res.data.industry || "")
						}
						if ($("#ent_area").attr("tmidx")) {
							if (res.data.regLocation && res.data.regLocation.indexOf("省") > 0) {
								this.$emit("setInput", $("#ent_area").attr("tmidx"), (res.data.regLocation.split('省')[0] + "省" + res.data.city + res.data.district) || "")
							} else {
								this.$emit("setInput", $("#ent_area").attr("tmidx"), (res.data.city + res.data.district) || "")
							}

						}


						// this.enterInfo.address = res.data.regLocation
						// this.enterInfo.firm_num = res.data.creditCode
					}
				})
			}
		},
		getStuInfo() {
			console.log("需要学生信息")
			this.$http.post("/api/get_stu_openinfo", { uid: sessionStorage.getItem("ivst_stu_id") }).then(res => {
				if (res.data && res.data.id) {
					setTimeout(() => {
						if ($("#stu_name").attr("tmidx")) {
							this.$emit("setInput", $("#stu_name").attr("tmidx"), res.data.stu_name || "")
						}
						if ($("#stu_phone").attr("tmidx")) {
							this.$emit("setInput", $("#stu_phone").attr("tmidx"), res.data.phone || "")
						}
						if ($("#stu_class").attr("tmidx")) {
							this.$emit("setInput", $("#stu_class").attr("tmidx"), (res.data.jc_name+"/"+res.data.zy_name+"/"+res.data.class_name) || "")
						}
					}, 200);
				}

			})
		}
	}

}
</script>

<style>
.inputBox .el-input .el-input__inner {
	color: #2c3e50;
}

.enteritem {
	padding: 10px 20px;
	border-bottom: 1px solid #eee;
}

.enteritem:active {
	background-color: #eee;
}
</style>
