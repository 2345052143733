<template>
  <div style="height: 100%; max-width: 600px; margin: 0 auto; position: relative;" id="mybox">


    <div style="height: 100%; background-size: 100% 100% ; background-image: url(./img/wj07.jpg);overflow:auto;">
      <div style="height: 30px;"></div>

      <div v-if="IvstInfo" style="text-align: center; padding: 20px; font-size: 20px; color: orangered;">姓名：{{IvstInfo.stu_name}}，总得分：{{IvstInfo.answers[0].score}}分</div>

      <div v-for="(wj, idx) in DataList" :key="idx" class="wj">

        <!-- <div class="tm">
          <div style="padding:10px;font-weight: bold;" v-html="wj.ivst_title">

          </div>
          <div style="padding:10px" v-html="wj.ivst_desc">

          </div>

        </div> -->


        <div class="tm" v-for="(tm, tmidx) in wj.tm_list" v-if="tm.show">
          <div class="tmlabel">
            {{ tmidx + 1 }}/{{ wj.tm_list.length }}
          </div>
          <div style="margin-bottom: 10px; color: #45488d; padding: 0 15px; font-weight: bold;">
            <div v-if="tm.tm_type == 'tiankong'">
              <inputdom :joined="joined" :tm="tm" :tmidx="tmidx" @setInput="setInput"></inputdom>

            </div>
            <div v-else>
              {{ tm.tm_title }}
            </div>
          </div>
          <div v-if="tm.tm_type == 'radio_normal'">
            <div v-for="(xx, xxidx) in tm.tm_content" :class="['xxitem', xx.choosed ? 'choosed' : '',xx.is_correct ? 'correct' : '']"
              @click="chooseXX(tm, xx)">

              {{ xx.title }}({{ xx.score }}分)

              <div style="float: right;">
                <i v-if="xx.choosed" class="iconfont">&#xe6a2;</i>
                <i v-else class="iconfont">&#xe619;</i>
              </div>


            </div>
          </div>

          <div v-if="tm.tm_type == 'checkbox_normal'">
            <div v-for="(xx, xxidx) in tm.tm_content" :class="['xxitem', xx.choosed ? 'choosed' : '',xx.is_correct ? 'correct' : '']"
              @click="chooseXXMore(tm, xx)">

              {{ xx.title }}
              <div style="float: right;">
                <i v-if="xx.choosed" class="iconfont">&#xe61b;</i>
                <i v-else class="iconfont">&#xe622;</i>
              </div>

            </div>
          </div>

          <div v-if="tm.tm_type == 'wenda'" style="width: 94%;margin: 0 auto;">
            <textarea
              style="border: 1px solid #0f9fff; background-color: #eaf7ff; width: 90%; border-radius: 5px; padding:3% 10px; outline: none; margin-bottom: 10px;"
              placeholder="请输入您的回答"></textarea>
          </div>

          <div v-if="tm.tm_type == 'radio_image'">
            <div v-for="(xx, xxidx) in tm.tm_content" :class="['xxitem', xx.choosed ? 'choosed' : '']"
              @click="chooseXX(tm, xx)">
              <i v-if="xx.choosed" class="iconfont">&#xe6a2;</i>
              <i v-else class="iconfont">&#xe619;</i>


              <img :src="xx.value" style="width: 80%;" />
            </div>
          </div>
          <div v-if="tm.tm_type == 'checkbox_image'">
            <div v-for="(xx, xxidx) in tm.tm_content" :class="['xxitem', xx.choosed ? 'choosed' : '']"
              @click="chooseXXMore(tm, xx)">
              <i v-if="xx.choosed" class="iconfont">&#xe61b;</i>
              <i v-else class="iconfont">&#xe622;</i>

              <img :src="xx.value" style="width: 80%;" />

            </div>
          </div>
        </div>

        <div style=" padding: 15px; padding-bottom: 200px;">
          <div class="btntj" v-if="!joined && !expired" @click="confirmTJ">提交</div>
        </div>

        <div style="height: 15px;"></div>


      </div>

    </div>


    <div id="toast" class="toast">
      请选择完整
    </div>

    <div id="alert" class="alert flexCenter">
      <div class="alertbox">
        <div class="alerttitle">提示</div>
        <div id="alertcontent" class="alertcontent"></div>
        <div class="alertbutton" @click="hideAlert">确定</div>
      </div>
    </div>


    <div id="alert2" class="alert flexCenter">
      <div class="alertbox">
        <div class="alerttitle">提示</div>
        <div class="alertcontent">确认提交？</div>
        <div class="alertbutton" @click="submitData">确定</div>
        <div style="text-align: center; color: #888; margin-top: 20px;" @click="cancelSubmit">取消</div>
      </div>
    </div>


    <div id="left_time" v-if="leftSecond>0" style="position: fixed; top: 10px; right: 10px; color:orangered">{{ time_min }}:{{ time_sec }}</div>


  </div>
</template>

<script>
import inputdom from "../h5/inputDom.vue"

export default {
  props:['tempid',"userid","plan_id"],
  components: {
    inputdom
  },
  data() {
    return {
      step: 0,
      ivst_id: 0,
      DataList: [],
      joined: false,
      viewResult: false,
      expired: false, //是否过期
      showUser: false,
      userMore: {
        class_name: "",
        phone: "",
        gyear: "",
        stu_name: ""
      },
      YearList: [],
      ClassList: [],
      cj_ing: false,
      prize_value: "",
      submitWjing: false,


      IvstInfo: null,
      color: ["#1fa4fb", "#3e6bff", "#139efc",
        "#149efc", "#38aaf5", "#2ea7f9",
        "#3f6cfe", "#109ffe", "#4375ff"],
      tm0List: [],
      ckeckedXX: 0,
      firstTM: null,
      timer:0,
      leftSecond:0,
      time_min:"",
      time_sec:"",
      

    }
  },
  mounted() {

    if (this.tempid) {
      this.getIvst()
    }

  },
  beforeDestroy(){
    clearInterval(this.timer)
  },
  methods: {
    daojishi(){
      this.timer = setInterval(() => {
        this.leftSecond--
        this.time_min = parseInt(this.leftSecond/60)
        this.time_sec = this.leftSecond%60
        if(this.time_min==0&&this.time_sec==0){
          this.submitData()
          clearInterval(this.timer)
        }
      }, 1000);
    },
    getIvst() {
      return new Promise(resolve => {
        this.$http.post("/api/ivst_temp_one", {
          plan_id: this.plan_id, id: this.tempid,
          pmid:window.pmid,
          stu_id: this.userid,
          utype: 0,

        }).then(res => {

          this.IvstInfo = res.data
          document.title = res.data.ivst_title

          if (res.data.answers && res.data.answers.length > 0) {

            this.joined = true
            this.getList(res)
           
          } else {
            if (res.data.hasAuth) {
              this.getList(res)
              if(res.data.ivst_type=="exam"){
                if(res.data.exam_min){
                  this.leftSecond = res.data.exam_min*60
                  this.daojishi()
                }
              }
            } else {
             
            }

          }
        })
      })

    },
    goNext(step) {
      let _this = this
      if (this.joined) {
     
      } else {

        this.step = step
        this.$nextTick(() => {
          $(window).scrollTop(0)
          if (step == 1) {
            if (_this.ckeckedXX) {
              this.getList();
            } else {
              _this.$message.error("请先选择当前状态")
              _this.step = 0
              return
            }
          }
        })


      }

    },

    setInput(idx, value) {
      let tm = this.DataList[0].tm_list[idx]
      this.DataList[0].tm_list[idx].answer = value
      if (tm.tm_title.indexOf("工作所在地")>=0) {//选择江苏省
				if (value.indexOf("江苏") >= 0) {
					for (let timu of this.DataList[0].tm_list) {
						if (timu.tm_title.indexOf("离开江苏")>=0) {
							timu.show = true
						}
						if (timu.tm_title.indexOf("回江苏")>=0) {
							timu.show = false
						}
					}
				} else {//选省外
					for (let timu of this.DataList[0].tm_list) {
						if (timu.tm_title.indexOf("离开江苏")>=0) {
							timu.show = false
						}
						if (timu.tm_title.indexOf("回江苏")>=0) {
							timu.show = true
						}
					}
				}

			}
      this.$forceUpdate()

    },
    getList(res) {

      let tmarr = [];

      if (res.data.answers && res.data.answers.length > 0) {
        this.joined = true

        for (let item of res.data.tm_list) {

          if (item.tm_content) {
            item.tm_content = JSON.parse(item.tm_content)
            for (let xx of item.tm_content) {
              xx.choosed = false
            }
          }



          if (true) {

           // item.tm_title = item.tm_title.split("（")[0].split("注：")[0]


            if (item.tm_type == "tiankong") {
              let answers = JSON.parse(res.data.answers[0].tm)
              item.show = false
              for (let ans of answers) {
              
                if (item.id == ans.tmid) {
                  item.show  = true
                  let xxobj = JSON.parse(ans.answers)
                  for (let itemxx in xxobj) {
                    console.log(333, itemxx)



                    item.tiankongAnswer = xxobj[itemxx]



                  }
                }
              }
             

            }
            else if (item.tm_type == "wenda") {
              let answers = JSON.parse(res.data.answers[0].tm)
              item.show = false
              for (let ans of answers) {
               
                if (item.id == ans.tmid) {
                  item.show = true
                  let xxobj = JSON.parse(ans.answers)
                  for (let itemxx in xxobj) {
                    console.log(333, itemxx)

                    setTimeout(function () {
                      // console.log(217,$(".tm").eq(index).find("textarea").val("Dolly Duck"))
                      $(".tm").eq(index).find("textarea").val(
                        `${xxobj[itemxx]}`)
                      $(".tm").eq(index).find("textarea").attr(
                        "disabled", true);
                    }, 200)

                  }
                }
              }


            }
            else {
              if (item.tm_content) {

                try {
                  item.show = false
                  for (let xx of item.tm_content) {
                    let answers = JSON.parse(res.data.answers[0].tm)
                    for (let ans of answers) {

                      if (item.id == ans.tmid) {
                        item.show = true
                        let xxobj = JSON.parse(ans.answers)
                        for (let itemxx in xxobj) {
                          console.log(333, itemxx)


                          // xx.choosed = false
                          if (xx.xxid == itemxx) {
                            xx.choosed = true
                          }
                          console.log(360, item.tm_content)


                        }
                      }
                    }
                  }
                } catch (err) {
                  console.log(350, err)
                }
              }
            }
           if(item.show){
            tmarr.push(item)
           }
            
          }


        }
      }
      else {

        let tm0 = res.data.tm_list[0];
        let jobtype = "";
        if (sessionStorage.getItem("firsttm")) {
          try {
            tm0.tm_content = JSON.parse(tm0.tm_content)
            let checkxx = sessionStorage.getItem("firsttm");
            checkxx=checkxx.split(";")
            for (let job of tm0.tm_content) {
              if (job.xxid == checkxx[1]) {
                jobtype = job.title
                this.ckeckedXX = job.xxid
                this.firstTM = tm0
                console.log(this.firstTM)
              }
            }
          } catch (error) {

          }

        }
        console.log("jobtype",jobtype)

        for (let item of res.data.tm_list) {


          if (item.tm_title.indexOf(jobtype) > 0 || item.tm_title.indexOf("所有人") > 0) {
								item.tm_title = item.tm_title.split("（")[0].split("注：")[0]
								if (item.tm_content) {
									item.tm_content = JSON.parse(item.tm_content)
									for (let xx of item.tm_content) {
										xx.choosed = false
									}
								}
								if (item.tm_type == "tiankong") {


									// item.tm_title = item.tm_title.replace(/___/g, "<input type='text' />").replace(
									// 	/_/g,
									// 	'')
								}
								item.show = true
								tmarr.push(item)
							}else{
               
								if (item.tm_content) {
									item.tm_content = JSON.parse(item.tm_content)
									for (let xx of item.tm_content) {
										xx.choosed = false
									}
								}
								if (item.tm_type == "tiankong") {


									// item.tm_title = item.tm_title.replace(/___/g, "<input type='text' />").replace(
									// 	/_/g,
									// 	'')
								}
								item.show = true
								tmarr.push(item)
              }

        }
      }
      res.data.tm_list = tmarr
      this.DataList = [res.data]
      if (this.joined) {
      
        
      }

    },
    chooseXX(tm, xx) {
      if (this.joined) {
        return
      }
      let tmLimits = []; //找出所有不可答的题目
      for (let txx of tm.tm_content) {
        txx.choosed = false;
        let tmLimit = txx.tmLimit.split(",")
        for (let tl of tmLimit) {
          if (tl) {
            tmLimits.push(tl)
          }
        }
      }
      console.log(tmLimits)
      xx.choosed = true;

      for (let tl of tmLimits) {
        for (let tm of this.DataList[0].tm_list) {
          if (tm.id == tl) {
            tm.show = true
          }
        }
      }

      if (xx.tmLimit) {
        //先将所有重置为可答，然后将此选项的重置为不可答

        for (let tm of this.DataList[0].tm_list) {
          let tmLimit = xx.tmLimit.split(",")

          for (let tl of tmLimit) {
            if (tm.id == tl) {
              tm.show = false

            }
          }

        }
      }


      this.$forceUpdate()


    },
    chooseXXMore(tm, xx) { //多选


      if (this.joined) {
        return
      }
      xx.choosed = !xx.choosed;
      if (xx.choosed) {
        //检查是否和其他选项不兼容
        if (xx.xxLimit) {

          let xxLimits = xx.xxLimit.split(",")
          for (let xl of xxLimits) {
            if (xl) {
              let idx = parseInt(xl) - 1
              tm.tm_content[idx].choosed = false
            }
          }

        }
        //检查是否大于选择项目
        let ckdcount = 0;
        for (let x of tm.tm_content) {
          if (x.choosed) {
            ckdcount++
          }
        }
        console.log(ckdcount, tm.max_cnt)
        if (ckdcount > tm.max_cnt) {
          xx.choosed = false
          this.showToast("最多选择" + tm.max_cnt + "项")

        }
      }

      let tmLimits = []; //找出所有不可答的题目
      for (let txx of tm.tm_content) {
        if (txx.tmLimit) {
          let tmLimit = txx.tmLimit.split(",")
          for (let tl of tmLimit) {
            if (tl) {
              tmLimits.push(tl)
            }
          }
        }
      }


      for (let tl of tmLimits) {
        for (let tm of this.DataList[0].tm_list) {
          if (tm.id == tl) {
            tm.show = true
          }
        }
      }
      let needLimit = [];
      for (let xx of tm.tm_content) {
        if (xx.choosed && xx.tmLimit) {
          let tmLimit = xx.tmLimit.split(",")
          for (let tl of tmLimit) {
            if (tl) {
              needLimit.push(tl)
            }
          }
        }
      }
      if (needLimit.length > 0) {
        //先将所有重置为可答，然后将此选项的重置为不可答

        for (let tm of this.DataList[0].tm_list) {
          for (let tl of needLimit) {
            if (tm.id == tl) {
              tm.show = false
            }
          }
        }
      }



      this.$forceUpdate()


    },
    showToast(msg) {
      $("#toast").text(msg).show().animate({
        opacity: 1
      }, 200, "linear", function () {
        setTimeout(() => {
          $("#toast").animate({
            opacity: 0
          }, "linear", 3000, function () {
            $("#toast").hide()
          })
        }, 2000)
      })
    },
    showAlert(msg) {
      $("#alertcontent").html(msg);
      $("#alert").css("display", "flex").animate({
        opacity: 1
      }, 200, "linear", function () {

      })
    },
    hideAlert() {
      $("#alert").animate({
        opacity: 0
      }, "linear", 3000, function () {
        $("#alert").hide()
      })
      if (this.viewResult) {
        //this.$router.push("/h5result/"+this.$route.params.id)
      }
    },
    showTJ() {
      this.$router.push("/h5result/" + this.$route.params.id)
    },
    submitData() {
      $("#alert2").animate({
        opacity: 0
      }, "linear", 3000, function () {
        $("#alert2").hide()
      })
      let total_value = 0;
      let total_score = 0;
      let resulttm = [];

      for (let wj of this.DataList) {
        let i = 1;
        //第一题
        if (this.ckeckedXX) {
          let result = {
            tmid: this.firstTM.id,
            answers: {},
            iscorrect: 0,
            score: 0
          }
          result.answers[this.ckeckedXX] = 1
          result.answers = JSON.stringify(result.answers)
          resulttm.push(result)
        }

        for (let tm of wj.tm_list) {

          let result = {
            tmid: tm.id,
            answers: {},
            iscorrect: 0,
            score: 0
          }
          if ((tm.tm_type.indexOf("radio") >= 0 || tm.tm_type.indexOf("checkbox") >= 0)&&tm.show) {
            result.iscorrect = 1
            let ckdcount = 0;
            for (let xx of tm.tm_content) {

              if (!xx.choosed && xx.is_correct) {
                result.iscorrect = 0
              }
              if (xx.choosed && !xx.is_correct) {
                result.iscorrect = 0
              }
              if (xx.choosed) {
                ckdcount++
                result.answers[xx.xxid] = 1
                result.score = xx.score ? xx.score : 0
                total_score += xx.score ? xx.score : 0
              }
            }

            if (ckdcount < tm.min_cnt) {
              this.showToast("第" + i + "题最少选择" + tm.min_cnt + "项！")
              return
            }

          } else if (tm.tm_type == 'tiankong') {
            result.iscorrect = -1

            result.answers[0] = tm.answer

          } else if (tm.tm_type == 'wenda') {

            result.iscorrect = -1

            let answer = $(".tm").eq(i - 1).find("textarea").val()

            if (answer) {
              result.answers[0] = answer

            }


          }
          result.answers = JSON.stringify(result.answers)
          resulttm.push(result)
          i++;
        }
      }
      let usetime = 0;
      if (this.totalsecond >= 0) {
        usetime = this.DataList[0].minute * 60 - this.totalsecond
      }


      this.$http.post("/api/ivst_join", {
        plan_id: this.plan_id,
        answer: resulttm,
        score: total_score,
        ivst_id: this.IvstInfo.id,

           uid: sessionStorage.getItem("ivst_stu_id"),
        pmid: sessionStorage.getItem("ivst_pmid"),
        utype: 0,
      }).then(res => {
        if (res.data.code == 200) {
          // this.showToast("您的答题结果已提交！")
           this.showAlert("您的答题结果已提交，感谢您的参与！")
          this.joined = true
          setTimeout(() => {
            window.history.back()
          }, 2000)
          clearInterval(this.timer)

        } else if (res.data.code == 201) {
          this.showToast("您无权参与此问卷！")

        }
      })



    },

    confirmTJ() {
      if (this.submitWjing) {

        return
      } else {
        this.submitWjing = true
        setTimeout(() => {
          this.submitWjing = false
        }, 3000)
      }



      //检查是否有未做的提
      for (let wj of this.DataList) {
        let i = 1;
        for (let tm of wj.tm_list) {
          if (tm.show) {
            if (tm.tm_type.indexOf("radio") >= 0 || tm.tm_type.indexOf("checkbox") >= 0) {
              let choosed = false;
              for (let xx of tm.tm_content) {
                if (xx.choosed) {
                  choosed = true;
                  break;
                }
              }
              if (!choosed) {
                this.showToast("请选择第" + i + "题目")

                $(".wj").scrollTop(0)
                $(".wj").scrollTop($(".tm").eq(i - 1).offset().top)
                return
              }
            } else if (tm.tm_type == 'tiankong') {
              if (!tm.answer) {
                this.showToast("请输入填空第" + i + "题目")
                $(".wj").scrollTop(0)
                $(".wj").scrollTop($(".tm").eq(i - 1).offset().top)
                return
              }

            } else if (tm.tm_type == 'wenda') {

              if (!$(".tm").eq(i - 1).find("textarea").val()) {
                this.showToast("请输入第" + i + "题目")

                $(".wj").scrollTop(0)
                $(".wj").scrollTop($(".tm").eq(i - 1).offset().top)
                return
              }

            }
          }
          i++;
        }


      }
      this.submitData()


    },
    cancelSubmit() {


      $("#alert2").animate({
        opacity: 0
      }, 200, "linear", function () {
        $("#alert2").hide()
      })
    },
  }
}
</script>

<style>
@font-face {
  font-family: 'iconfont';
  /* project id 1542156 */
  src: url('//at.alicdn.com/t/font_1542156_1tczn3jwr3n.eot');
  src: url('//at.alicdn.com/t/font_1542156_1tczn3jwr3n.eot?#iefix') format('embedded-opentype'),
    url('//at.alicdn.com/t/font_1542156_1tczn3jwr3n.woff2') format('woff2'),
    url('//at.alicdn.com/t/font_1542156_1tczn3jwr3n.woff') format('woff'),
    url('//at.alicdn.com/t/font_1542156_1tczn3jwr3n.ttf') format('truetype'),
    url('//at.alicdn.com/t/font_1542156_1tczn3jwr3n.svg#iconfont') format('svg');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>

<style scoped>
.wj {
  padding: 0 15px;
  word-break: break-all;

}

.tm {
  border: 3px solid #0f9fff;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 3px 1px 1px #2226bc;

}

.tmlabel {
  width: 80px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background: linear-gradient(to right, #8bd0fd, #0f9fff);
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  margin: 10px 0 10px 0;
  margin-left: -3px;
  color: #fff;
}

.required {
  color: orangered;
}

.notrequired {
  color: #FFFFFF;
}

.xxitem {
  width: 80%;
  padding: 10px;
  border: 1px solid #f2f2f2;
  margin: 0 auto;
  margin-bottom: 10px;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.choosed {
  color: #fff;
  border: 1px solid #007AFF;
  background-color: #007AFF;

}

.iconfont {
  display: inline-block;
  transform: translateY(2px);
  vertical-align: top;
}

.btntj {
  height: 48px;
  width: 60%;
  margin: 0 auto;
  border: 4px solid #fff;
  text-align: center;
  line-height: 40px;
  background: #007AFF;
  color: #FFFFFF;
  border-radius: 24px;
}

.toast {
  position: fixed;
  width: 70%;
  left: 15%;
  text-align: center;
  padding: 5px 0;
  border-radius: 4px;
  bottom: 50%;
  background: orangered;
  color: #FFFFFF;
  font-size: 14px;
  opacity: 0;
  display: none;
}

.alert {
  position: absolute;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
}

.alertbox {
  width: 80%;
  background: #FFFFFF;
  border-radius: 4px;
  padding: 10px;
  margin: 0 auto;
}

.alerttitle {
  font-size: 16px;
  text-align: center;
  border-bottom: 1px solid #f6f6f6;
  padding-bottom: 10px;
}

.alertcontent {
  padding: 20px 0;
  word-break: break-all;
  text-align: center;
}

.alertbutton {
  border-top: 1px solid #f6f6f6;
  padding: 10px;
  text-align: center;
  background-color: #007AFF;
  color: #fff;
  border-radius: 6px;
  cursor: pointer;

}

.tiankong {
  display: inline-block;
}

.tiankong>input[type='text'] {
  border: 0;
  outline: none;
  width: 100px;
  border-bottom: 1px solid #888;
  text-align: center;
}
.correct{ background-color: lightgreen;}
</style>
