<template>

    <div class="tnode" v-if="NodeInfo">
        <div style="height: 30px; line-height: 30px; padding-left: 20px;">
            <div class="trlabel"></div>
            {{ NodeInfo.name }}  <span class="quanxuan" @click="chsAll()"> <i class="el-icon-circle-check"></i> 全选</span>
        </div>
        <div v-if="NodeInfo.teachers" class="techbox" >
            <div :class="['techitem',t.ckd?'techckd':'']" v-for="(t,i) in NodeInfo.teachers" @click="setCkd(i)">
                <img :src="t.avatar?t.avatar:'/img/temp.png'" />
                <span>
                    {{t.username}}
                </span>    
            </div>
          
        </div>
        <div v-if="NodeInfo.children && NodeInfo.children.length > 0">
            <tnode v-for="(item, idx) in NodeInfo.children" :key="idx" :tdata="item"  >
                {{ item.name }}
            </tnode>
        </div>

    </div>

</template>

<script>
import tnode from "./tnode.vue"
export default {
    props: ["tdata","oteachers"],
    name: "tnode",
    components: {
        tnode
    },
    data() {
        return {
            NodeInfo:null,
            
        }
    },
   
    mounted() {
        this.NodeInfo = this.tdata
        
        
    },
    methods: {
        setCkd(i){
            this.NodeInfo.teachers[i].ckd = !this.NodeInfo.teachers[i].ckd
            this.$forceUpdate()
            this.getCkdData(this.NodeInfo.teachers[i])
        },
        getCkdData(e){
            this.$$parent(this,"setCkdTeachers",e)
        },
        chsAll(){
            let NodeInfo = JSON.parse(JSON.stringify(this.NodeInfo))
            this.NodeInfo=null
            let diguiCk=e=>{
                if(e.teachers&&e.teachers.length>0){
                    e.teachers.map(item=>{
                        item.ckd=true;
                        this.$$parent(this,"setCkdTeachers",item)
                    })
                }
                if(e.children&&e.children.length>0){
                    for(let c of e.children){
                        diguiCk(c)
                    }
                }
            }
            diguiCk(NodeInfo)
            setTimeout(()=>{
                this.NodeInfo=NodeInfo
            },0)
        }
    }
}

</script>
<style scoped>
.tnode {
    
    min-height: 40px;
    border-left: 1px dashed #888;
    position: relative;
    border-collapse: collapse;
    margin-left: 10px;
}

.trlabel {
    width: 20px;
    height: 30px;
    border: 1px dashed #888;
    position: absolute;
    border-right: 0;
    border-top: 0;
    top: 18px;
    left: 10px;
}
.techbox{ margin-left: 40px;}
.techitem{ display: inline-block; margin: 10px 10px 0 0; border: 1px solid lightblue; padding:0 5px; border-radius: 4px; cursor: pointer;  }
.techitem img{ width: 20px; height: 20px; vertical-align: middle; border-radius: 50%; transform: translateY(-1px);}
.techckd{ background-color: #409EFF; color: #fff;}
.quanxuan{ font-size: 12px; margin-left: 20px; display: inline-block; transform: scale(0.8,0.8); color: darkgray;cursor: pointer;}
</style>