<template>
  <div style="height: 100%;">
    <div v-for="(wj,idx) in DataList" :key="idx" class="wj">
      <div style="height: 15px;"></div>

      <div style="font-size: 16px; font-weight: bold;">{{wj.ivst_title}}</div>
       <div style=" padding: 10px 0; text-align: right; color: #888888;"><i class="iconfont">&#xe642;</i>{{wj.joinusers.length}}/{{wj.usernum}}</div>
      <div style=" padding: 10px 0;">{{wj.ivst_desc}}</div>

      <div v-for="(tm,tmidx) in wj.tm_list" style="margin-bottom: 20px;">
        <div style="margin-bottom: 10px;">

          <span class="required" v-if="tm.required>0">*</span>
          <span class="notrequired" v-else>*</span>
          {{tmidx+1}}、{{tm.tm_title}}
        </div>
        <div v-if="tm.tm_type==('radio_normal')">
          <div v-for="(xx,xxidx) in tm.tm_content" class="xxitem" @click="chooseXX(tm,xx)">
            <span class="numletter">{{letterNum(xxidx)}}</span>
            <i class="iconfont">&#xe619;</i>
            {{xx.title}}
            <div class="processbar">
              <div class="processin" :style="{width:xx.checkeduser/joinNum*100+'%'}"></div>
              <div class="processtxt">{{xx.checkeduser}}/{{joinNum}}</div>
            </div>
          </div>
        </div>
        <div v-if="tm.tm_type==('radio_image')">
          <div style="  vertical-align: top;" v-for="(xx,xxidx) in tm.tm_content" class="xxitem" @click="chooseXX(tm,xx)">
            <span class="numletter">{{letterNum(xxidx)}}</span>
            <i class="iconfont">&#xe619;</i>
            <div style="display: inline-block; width: 30%; padding-left: 10%; ">
              <img :src="xx.value" style="width: 100%;" />
            </div>
            <div style="padding-top:5px ;">
              {{xx.title}}
            </div>
            <div class="processbar">
              <div class="processin" :style="{width:xx.checkeduser/joinNum*100+'%'}"></div>
              <div class="processtxt">{{xx.checkeduser}}/{{joinNum}}</div>
            </div>
          </div>
        </div>

        <div v-if="tm.tm_type==('vote_normal')">
          <div v-for="(xx,xxidx) in tm.tm_content" class="xxitem" @click="chooseXX(tm,xx)">
            <span class="numletter">{{letterNum(xxidx)}}</span>
            <i class="iconfont">&#xe619;</i>
            {{xx.title}}
            <div class="processbar">
              <div class="processin" :style="{width:xx.checkeduser/joinNum*100+'%'}"></div>
              <div class="processtxt">{{xx.checkeduser}}/{{joinNum}}</div>
            </div>
          </div>
        </div>
        <div v-if="tm.tm_type==('chart_normal')">
          <div v-for="(xx,xxidx) in tm.tm_content" class="xxitem" @click="chooseXX(tm,xx)">
            <span class="numletter">{{letterNum(xxidx)}}</span>
            <i class="iconfont">&#xe619;</i>
            {{xx.title}}
            <div class="processbar">
              <div class="processin" :style="{width:xx.checkeduser/joinNum*100+'%'}"></div>
              <div class="processtxt">{{xx.checkeduser}}/{{joinNum}}</div>
            </div>
          </div>
        </div>
        <div v-if="tm.tm_type==('vote_image')">
          <div style=" vertical-align: top;" v-for="(xx,xxidx) in tm.tm_content" class="xxitem" @click="chooseXX(tm,xx)">
            <span class="numletter">{{letterNum(xxidx)}}</span>
            <i class="iconfont">&#xe619;</i>
            <div style="display: inline-block; width: 30%; padding-left: 10%; ">
              <img :src="xx.value" style="width: 100%;" />
            </div>
            <div style="padding-top:5px ;">
              {{xx.title}}
              <div class="processbar">
                <div class="processin" :style="{width:xx.checkeduser/joinNum*100+'%'}"></div>
                <div class="processtxt">{{xx.checkeduser}}/{{joinNum}}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="tm.tm_type==('chart_image')">
          <div style="vertical-align: top;" v-for="(xx,xxidx) in tm.tm_content" class="xxitem" @click="chooseXX(tm,xx)">
            <span class="numletter">{{letterNum(xxidx)}}</span>
            <i class="iconfont">&#xe619;</i>
            <div style="display: inline-block; width: 60%; padding-left: 10%; ">
              <img :src="xx.value" style="width: 100%;" />
            </div>
            <div style="padding-top:5px ;">
              {{xx.title}}
              <div class="processbar">
                <div class="processin" :style="{width:xx.checkeduser/joinNum*100+'%'}"></div>
                <div class="processtxt">{{xx.checkeduser}}/{{joinNum}}</div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="tm.tm_type==('checkbox_normal')">
          <div v-for="(xx,xxidx) in tm.tm_content" class="xxitem" @click="chooseXXMore(tm,xx)">
            <span class="numletter">{{letterNum(xxidx)}}</span>
            <i class="iconfont">&#xe622;</i>
            {{xx.title}}
            <div class="processbar">
              <div class="processin" :style="{width:xx.checkeduser/joinNum*100+'%'}"></div>
              <div class="processtxt">{{xx.checkeduser}}/{{joinNum}}</div>
            </div>
          </div>

        </div>
        <div v-if="tm.tm_type==('checkbox_image')">
          <div style="vertical-align: top;" v-for="(xx,xxidx) in tm.tm_content" class="xxitem" @click="chooseXXMore(tm,xx)">
            <span class="numletter">{{letterNum(xxidx)}}</span>
            <i class="iconfont">&#xe622;</i>
            <div style="display: inline-block; width: 30%; padding-left: 10%; ">
              <img :src="xx.value" style="width: 100%;" />
            </div>
            <div style="padding-top:5px ;">
              {{xx.title}}
              <div class="processbar">
                <div class="processin" :style="{width:xx.checkeduser/joinNum*100+'%'}"></div>
                <div class="processtxt">{{xx.checkeduser}}/{{joinNum}}</div>
              </div>
            </div>
          </div>
        </div>

        <div :id="'chart_'+tm.id" style="height: 200px;"></div>


      </div>



      <div style="height: 15px;"></div>



    </div>


    <div id="toast" class="toast">
      请完善信息
    </div>

    <div id="alert" class="alert flexcenter">
      <div class="alertbox">
        <div class="alerttitle">提示</div>
        <div id="alertcontent" class="alertcontent"></div>
        <div class="alertbutton" @click="hideAlert">确定</div>
      </div>
    </div>


  </div>
</template>

<script>
  export default {
     props:['ivstid'],
    data() {
      return {
        DataList: [],
        joined: false,
        joinNum:0
      }
    },
    mounted() {

      this.getList();
    },

    methods: {
      letterNum(idx) {
        let N = "";
        switch (idx) {
          case 0:
            N = "A";
            break;
          case 1:
            N = "B";
            break;
          case 2:
            N = "C";
            break;
          case 3:
            N = "D";
            break;
          case 4:
            N = "E";
            break;
          case 5:
            N = "F";
            break;
          case 6:
            N = "G";
            break;
          case 7:
            N = "H";
            break;
          case 8:
            N = "I";
            break;
          case 9:
            N = "J";
            break;
          case 10:
            N = "K";
            break;
          case 11:
            N = "L";
            break;
          case 12:
            N = "M";
            break;


        }
        return N
      },
      getList(page) {
        this.$http.post("/admin/ivst_one", {
          id: this.$route.params.id||this.ivstid,
          all:1
        }).then(
          res => {

            res.data.usernum = 0;
            let arr = res.data.target_user.split(",");
            for (let u of arr) {
            	if (u && u != "0") {
            		res.data.usernum++;
            	}
            }

            for(let joinuser of res.data.joinusers){
              joinuser.tm = JSON.parse(joinuser.tm)
            }
            for (let tm of res.data.tm_list) {
              tm.tm_content = JSON.parse( tm.tm_content);
              for(let xx of tm.tm_content){
                xx.checkeduser=0;
                for(let joinuser of res.data.joinusers){
                  for(let u_tm of joinuser.tm){
                    if(u_tm.id==tm.id){
                      for(let u_xx of u_tm.tm_content){
                        if(u_xx.xxid==xx.xxid&&u_xx.choosed){
                          xx.checkeduser++;
                        }
                      }
                      break
                    }
                  }
                }
              }
            }
            this.DataList = [res.data];
            this.joinNum = res.data.joinusers.length
            setTimeout(() => {
              for(let item of res.data.tm_list){
                this.setChart(item.id,item)
              }
            }, 800)

          })
      },
      chooseXX(tm, xx) {
        for (let txx of tm.tm_content) {
          txx.choosed = false;
        }
        xx.choosed = true;

      },
      chooseXXMore(tm, xx) { //多选
        xx.choosed = !xx.choosed;
      },
      showToast(msg) {
        $("#toast").text(msg).show().animate({
          opacity: 1
        }, 200, "linear", function() {
          setTimeout(() => {
            $("#toast").animate({
              opacity: 0
            }, "linear", 3000, function() {
              $("#toast").hide()
            })
          }, 2000)
        })
      },
      showAlert(msg) {
        $("#alertcontent").html(msg);
        $("#alert").css("display", "flex").animate({
          opacity: 1
        }, 200, "linear", function() {

        })
      },
      hideAlert() {
        $("#alert").animate({
          opacity: 0
        }, "linear", 3000, function() {
          $("#alert").hide()
        })
      },
      submitData() {

      },
      setChart(id,data) {

        console.log(data)
        let xdata=[];
        let valuedata=[];
        let idx=0;
        for(let item of data.tm_content){
          valuedata.push(item.checkeduser)
          xdata.push(this.letterNum(idx++))

        }

        //时间段排序
        let myChart = this.$echarts.init(document.getElementById('chart_'+id))
        myChart.clear();
        let option = {
          grid: {
            top: '4%',
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },

          xAxis: {
            type: 'category',
            axisTick: {
              alignWithLabel: true
            },
            data: xdata
          },
          yAxis: {
            type: 'value',
          },
          series: [{
            name: '用户数',
            type: 'bar',
            barWidth: '40%',
            label: {
              show: true,
              position: 'inside'
            },
            data: valuedata
          }]
        };




        myChart.setOption(option, true)
      },





    }
  }
</script>

<style scoped>
  .wj {
    padding: 0 15px;
    word-break: break-all;
    overflow: auto;
    background-size: cover;
    height: 100%;
  }

  .required {
    color: orangered;
  }

  .notrequired {
    color: #FFFFFF;
  }

  .xxitem {
    margin-left: 25px;
    padding: 10px;
    border: 1px solid #f6f6f6;
    margin-bottom: 5px;
    border-radius: 4px;
  }

  .choosed {
    color: #007AFF;
    border: 1px solid #007AFF;

  }

  .iconfont {
    display: inline-block;
    transform: translateY(2px);
    vertical-align: top;
  }

  .btntj {
    height: 40px;
    text-align: center;
    line-height: 40px;
    background: #007AFF;
    color: #FFFFFF;
    border-radius: 4px;
  }

  .toast {
    position: fixed;
    width: 70%;
    left: 15%;
    text-align: center;
    padding: 5px 0;
    border-radius: 4px;
    bottom: 50%;
    background: orangered;
    color: #FFFFFF;
    font-size: 14px;
    opacity: 0;
    display: none;
  }

  .alert {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 99;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0;
  }

  .alertbox {
    width: 80%;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 10px;
  }

  .alerttitle {
    font-size: 16px;
    text-align: center;
    border-bottom: 1px solid #f6f6f6;
    padding-bottom: 10px;
  }

  .alertcontent {
    padding: 20px 0;
    word-break: break-all;
    text-align: center;
  }

  .alertbutton {
    border-top: 1px solid #f6f6f6;
    padding-top: 10px;
    text-align: center;
  }

  .processbar {
    height: 16px;
    border: 1px solid #f6f6f6;
    position: relative;
    border-radius: 8px;
    margin-top: 5px;
  }

  .processin {
    height: 100%;
    width: 0%;
    background: #409EFF;
    border-radius: 10px;
    transition: all 1s;
  }

  .processtxt {
    text-align: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    font-size: 10px;
    color: orangered;
    line-height: 16px;

  }
  .numletter{ vertical-align: top;}
</style>
